import React, { FunctionComponent } from 'react'
import { FeatureToggleUserZoom } from '@wh/common/chapter/components/UserZoom/UserZoom'

export const MyProfileUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQxMSAg" toggle="enableUserZoomMyProfile" />
}

export const AutoMotorResultListUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQxMCAg" toggle="enableUserZoomAutoMotorResultList" />
}

export const AutoMotorAdDetailUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQxMCAg" toggle="enableUserZoomAutoMotorAdDetail" />
}

export const AutoMotorStartpagesUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQxMCAg" toggle="enableUserZoomAutoMotorStartpages" />
}

export const RealEstateResultListUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQ5" toggle="enableUserZoomRealEstateResultList" />
}

export const RealEstateDetailSearchUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQ5" toggle="enableUserZoomRealEstateDetailSearch" />
}

export const RealEstateStartpageUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQ5" toggle="enableUserZoomRealEstateStartpage" />
}

export const RealEstateAdDetailUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQ5" toggle="enableUserZoomRealEstateAdDetail" />
}

export const NcAdDetailUserZoom: FunctionComponent<{}> = () => {
    return null
}

export const BapAdDetailUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQ3" toggle="enableUserZoomBapAdDetail" />
}

export const BapResultListUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQ3" toggle="enableUserZoomBapResultList" />
}

export const BapStartPageUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQ3" toggle="enableUserZoomBapStartPage" />
}

export const StartPageUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQzNCAg" toggle="enableUserZoomStartPage" />
}

export const FashionUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQzNSAg" toggle="enableUserZoomFashion" />
}

export const PriceFinderUserZoom: FunctionComponent<{}> = () => {
    return <FeatureToggleUserZoom interceptCode="QzU1OFQzOCAg" toggle="enableUserZoomPriceFinder" />
}
