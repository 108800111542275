
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/iad/immobilien/[...seopath]",
      function () {
        return require("private-next-pages/iad/immobilien/[...seopath].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/iad/immobilien/[...seopath]"])
      });
    }
  