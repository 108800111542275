import React, { Fragment } from 'react'
import { NextPage } from 'next'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { RealEstateResultListContainer } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/RealEstateResultListContainer'
import { RealEstateResultListUserZoom } from '@bbx/common/components/UserZoom/UserZoom'
import { ErrorPage } from '@wh/common/chapter/components/ErrorPage/ErrorPage'
import { ExpressNextContextWithUserData } from '@wh/common/chapter/types/nextJS'
import { concatPathWithQueryParams, getFirstString } from '@wh/common/chapter/lib/urlHelpers'
import { getSeoSearchResult, getSeoSearchTerms } from '@bbx/search-journey/common/api/searchApiClient'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { SeoSearchTerms } from '@bbx/search-journey/common/SeoSearchTerms'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { LeaderboardAndSkyscraperLayout } from '@wh/common/chapter/components/Layouts/LeaderboardAndSkyscraperLayout'

type ResultListProps =
    | {
          searchResult: SearchResult
          searchTerms: SeoSearchTerms
          fromExpiredAdId?: string | undefined
      }
    | {
          is404: true
      }

const ResultList: NextPage<ResultListProps> & { Layout: typeof Layout } = (props) => {
    if ('is404' in props) {
        return <ErrorPage statusCode={404} />
    } else {
        return (
            <Fragment>
                <RealEstateResultListUserZoom />
                <RealEstateResultListContainer
                    searchResult={props.searchResult}
                    searchTerms={props.searchTerms}
                    fromExpiredAdId={props.fromExpiredAdId}
                />
            </Fragment>
        )
    }
}

const resultListGetInitialProps = async ({ query, req, res }: ExpressNextContextWithUserData): Promise<ResultListProps> => {
    const { seopath, fromExpiredAdId: rawFromExpiredAdId, ...remainingQuery } = query
    const path = ['/immobilien', ...(seopath as string[])].join('/')
    const fromExpiredAdId = getFirstString(rawFromExpiredAdId)

    if (!path) {
        if (res) {
            res.statusCode = 404
        }
        return { is404: true }
    }

    try {
        const concatenatedPath = concatPathWithQueryParams(path, remainingQuery)

        const searchTermsPromise = getSeoSearchTerms(verticalIdMap.ESTATE)
        const searchResultPromise = getSeoSearchResult(concatenatedPath, req)

        const [searchResult, searchTerms] = await Promise.all([searchResultPromise, searchTermsPromise])
        return { searchResult, searchTerms, fromExpiredAdId }
    } catch (error) {
        const apiError = wrapInApiErrorIfNecessary(error)

        if (apiError.statusCode && apiError.statusCode >= 400 && apiError.statusCode <= 499) {
            if (res) {
                res.statusCode = 404
            }
            return { is404: true }
        }

        throw apiError
    }
}

ResultList.getInitialProps = resultListGetInitialProps
ResultList.Layout = LeaderboardAndSkyscraperLayout

export default ResultList
